window.addEventListener("load", function () {
    const addNewQuestion = document.getElementById('addNewQuestion');
    const questionsItems = this.document.getElementById('questionsItems');

    if (addNewQuestion && questionsItems) {
        let x = 1;
        addNewQuestion.addEventListener('click', function () {
            const questionItem = questionsItems.querySelectorAll('.question-item')[0].cloneNode(true);
            questionItem.querySelectorAll('input').forEach(element => {
                x++;
                element.value = '';
                element.setAttribute('name', element.getAttribute('name').replace(/\d+/, x));
            });
            questionsItems.appendChild(questionItem);
        });
    }
});
