document.addEventListener("DOMContentLoaded", function (event) {
    const mustConfirm = document.querySelectorAll('.must-confirm');

    mustConfirm.forEach(function (button) {
        button.addEventListener('click', function (e) {
            if (!window.confirm("Doopravdy chcete pokračovat?")) {
                e.preventDefault();
            }
        });
    });
});