import 'bootstrap';
import lozad from 'lozad';
var SimpleLightbox = require('simple-lightbox');

import './components/carousel';
import './components/my-acc';
import './components/countdown';
import './components/course';
import './components/imageUpload';
import './components/draggable';
import './components/tests';
import './components/confirm';
import './components/cart';
import './components/cookies';
import './admin.js';
import './preventDoubleSubmit';
import 'material-icons/iconfont/material-icons.css';
import { Loader } from './components/loader';
/*************/

const scrollToTop = document.getElementsByClassName('scroll-to-top');

Array.from(scrollToTop).forEach(el => {
    el.addEventListener('click', () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    });
});

/*************/

window.addEventListener("load", function () {
    const container = document.getElementsByClassName('detail-container')[0];
    const el = document.getElementsByClassName('bg-full-light')[0];

    if (el) {
        el.style.left = -container.offsetLeft - 39 + "px";
    }
});

window.addEventListener("resize", function () {
    const container = document.getElementsByClassName('detail-container')[0];
    const el = document.getElementsByClassName('bg-full-light')[0];

    if (el) {
        el.style.left = -container.offsetLeft - 39 + "px";
    }
});

/*************/
if (document.getElementsByClassName('btn-show-all-JS')[0]) {
    document.getElementsByClassName('btn-show-all-JS')[0].addEventListener("click", function () {
        const timeline = document.getElementsByClassName('timeline')[0];
        const timelineItems = timeline.querySelectorAll('.timeline-item');

        this.classList.add('d-none');
        timeline.classList.add('show-all');

        for (let item of timelineItems) {
            item.classList.remove('d-none')
        }
    });
}

document.addEventListener('DOMContentLoaded', function () {
    //new SimpleLightbox('.gallery .gallery-link');
    var lightbox = new SimpleLightbox({ elements: '.gallery .gallery-link' });
});

/*************/




window.addEventListener("load", function () {
    const ppc = this.document.querySelectorAll('.progress-pie-chart');

    if (ppc.length) {
        ppc.forEach(el => {
            const ppf = el.querySelector('.ppc-progress-fill');
            const percentText = el.querySelector('.percent-value');
            const percent = parseInt(el.dataset.percent);
            let deg = 360 * percent / 100;

            if (percent >= 50) {
                el.classList.add('gt-50');
            }

            if (percent >= 80) {
                el.classList.add('gt-80');
            }

            ppf.style.transform = 'rotate(' + deg + 'deg)';
            percentText.innerHTML = percent;
        });
    }
});


window.addEventListener("load", function () {


    const myCollapsible = document.querySelectorAll('.faq-collapse');

    if (myCollapsible.length) {
        myCollapsible.forEach(el => {
            el.addEventListener('show.bs.collapse', function () {
                //this.parentNode.classList.add('active');
                this.parentNode.classList.add('active-title');
                //this.parentNode.querySelector('.collapse-icon').classList.add('white');
            });

            el.addEventListener('hide.bs.collapse', function () {
                //this.parentNode.classList.remove('active');
                this.parentNode.classList.remove('active-title');
                //this.parentNode.querySelector('.collapse-icon').classList.remove('white');
            });
        });
    }

});


/************/

document.addEventListener('DOMContentLoaded', function () {
    const coursesFilter = document.getElementById('coursesFilter');
    const loader = new Loader();

    if (coursesFilter) {
        coursesFilter.addEventListener('change', function () {
            loader.show();
            this.submit();
        });
    }
});


// fullscreen content
const fullscreenContent = document.querySelectorAll('.fullscreen-content');

if (fullscreenContent) {
    const navbarHeight = document.querySelector('.navbar').offsetHeight;
    const footerHeight = document.querySelector('.footer').offsetHeight;
    const footerMarginTop = parseInt(window.getComputedStyle(document.querySelector('.footer')).marginTop);


    fullscreenContent.forEach(el => {
        el.style.height = window.innerHeight - navbarHeight - footerHeight - footerMarginTop + 'px';
    });
}

const observer = lozad(); // Keep it down as possible
observer.observe();



document.addEventListener('DOMContentLoaded', function () {
    const uploadImagesWithPreview = document.querySelectorAll('.upload-image-with-preview');

    if (uploadImagesWithPreview) {
        uploadImagesWithPreview.forEach(uploadImageWithPreview => {

            uploadImageWithPreview.addEventListener('change', function () {
                if (this.files[0]) {
                    var picture = new FileReader();
                    picture.readAsDataURL(this.files[0]);
                    picture.addEventListener('load', function (event) {
                        uploadImageWithPreview.parentNode.querySelector('.uploaded-image').setAttribute('src', event.target.result);
                        uploadImageWithPreview.parentNode.querySelector('.uploaded-image').classList.remove('d-none');
                    });
                }
            });
        });
    }
});


document.addEventListener('DOMContentLoaded', function () {
    const fsPage = document.querySelector('.fs-page');

    if (fsPage) {
        const navbarHeight = document.querySelector('.navbar').offsetHeight;
        const footerHeight = document.querySelector('.footer').offsetHeight;
        const footerMarginTop = parseInt(window.getComputedStyle(document.querySelector('.footer')).marginTop);

        fsPage.style.height = window.innerHeight - navbarHeight - footerHeight - footerMarginTop + 'px';
    }
});