import Quill from 'quill';


function getVariableTypeInput(type) {
    const getDataUrl = `/admin/page-variables/${type}/get-input`;
    const variableTypeBody = document.getElementById('variableTypeBody');

    fetch(getDataUrl, {
        method: 'GET',
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        },
        mode: 'cors',
        cache: 'default',
    })
        .then(result => result.text())
        .then(data => {
            variableTypeBody.innerHTML = data;
            const quill = document.getElementsByClassName('update-variable-quill');

            const toolbarOptionsProfile = [
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }]
            ];

            if (quill.length) {
                const content = document.querySelector('.update-variable-editorjs');

                let editor = new Quill('.update-variable-editorjs', {
                    modules: {
                        toolbar: toolbarOptionsProfile
                    },
                    theme: 'snow'
                });

                if (content) {
                    document.getElementById('addVariableForm').addEventListener('submit', function (e) {
                        e.preventDefault;
                        let content = document.querySelector('input[name=value]');
                        content.value = editor.root.innerHTML;
                    })
                }
            }
        })
        .catch((error) => {
            console.log('Error:', error);
        });
}

function getGroupRepeaterFields(id) {
    const url = `/admin/page-variables-group/${id}/get-group-repeater-fields`;
    const variableRepeaterBody = document.getElementById('variableRepeaterBody');

    fetch(url, {
        method: 'GET',
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        },
        mode: 'cors',
        cache: 'default',
    })
        .then(result => result.text())
        .then(data => {
            variableRepeaterBody.innerHTML = data
        })
        .catch((error) => {
            console.log('Error:', error);
        });
}

document.addEventListener("DOMContentLoaded", function (event) {
    const selectTypes = document.querySelectorAll('#selectType');
    
    if (selectTypes) {

        selectTypes.forEach(function(selectType) {
            selectType.addEventListener('change', function (event) {
                const type = selectType.value;
                getVariableTypeInput(type);

                
            });
        });
    }
});

document.addEventListener("DOMContentLoaded", function (event) {
    const addVariableModal = document.getElementById('addVariableModal');

    if (addVariableModal) {
        addVariableModal.addEventListener('show.bs.modal', function (event) {
            const groupId = event.relatedTarget.getAttribute('data-group-id');
            const pageVariableGroupIdInput = document.getElementById('pageVariableGroupIdInput');

            pageVariableGroupIdInput.value = groupId;

            getGroupRepeaterFields(groupId);
        });
    }
});