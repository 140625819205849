import 'vanilla-cookieconsent';

window.addEventListener('load', function () {
    var cookieconsent = initCookieConsent();

    cookieconsent.run({
        current_lang: 'cs',
        page_scripts: true,

        autorun: true,
        delay: 0,
        autoclear_cookies: true,

        theme_css: 'https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.8/dist/cookieconsent.css',

        gui_options: {
            consent_modal: {
                layout: 'cloud', // box/cloud/bar 
                position: 'bottom center', // bottom/top + left/right/center
                transition: 'slide' // zoom/slide
            },
            settings_modal: {
                layout: 'box', // box/bar
                transition: 'slide', // zoom/slide
            }
        },

        languages: {
            'cs': {
                consent_modal: {
                    title: "Informace o cookies",
                    description: 'Soubory cookies a další technologie nám pomáhají zlepšovat naše služby, pomáhají nám analyzovat výkon webu a umožňují nám pomáhat zákazníkům ve výběru správného zboží. V nastavení si můžete vybrat, které cookies můžeme používat. Svůj souhlas můžete kdykoliv odvolat.',
                    primary_btn: {
                        text: 'Rozumím',
                        role: 'accept_all' //'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Přijmout nezbytné',
                        role: 'accept_necessary' //'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Nastavení cookies',
                    save_settings_btn: "Uložit nastavení",
                    accept_all_btn: "Souhlasím se vším",
                    close_btn_label: "Zavřít",
                    cookie_table_headers: [{
                        col1: "Cookie"
                    },
                    {
                        col2: "Popis"
                    },

                    ],
                    blocks: [{
                        title: "Používáme cookies",
                        description: 'Soubory cookie používáme k analýze údajů o našich návštěvnících, ke zlepšení našich webových stránek, zobrazení personalizovaného obsahu a k tomu, abychom vám poskytli skvělý zážitek z webu. Používáním tohoto webu s tímto používáním souhlasíte.',
                    }, {
                        title: "Technické cookies",
                        description: 'Tyto cookies jsou nezbytně nutné pro správnou funkci webu - zajišťují správné zobrazení, odesílání formulářů, vkládání zboží do košíku a podobně. Technické cookies není možné vypnout.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true
                        },
                        cookie_table: [{
                            col1: 'cc_cookie',
                            col2: 'Vaše nastavení cookies z tohoto dialogu'
                        }]
                    }, {
                        title: "Analytické cookies",
                        description: 'Analytické cookies používáme k vyhodnocování výkonu našeho webu a reklamních kampaní. Pomocí cookies určujeme počet návštěv, zdroje návštěvnosti a chování zákazníků na našem webu - např. informace o tom, co na webu hledáte a které informace jsou pro vás nejdůležitější. Na základě těchto dat můžeme provádět optimalizaci webu a vylepšovat naše služby. ',
                        toggle: {
                            value: 'analytics',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [{
                            col1: '_ga/_ga*, _gid',
                            col2: 'Google Analytics - ukládá a počítá počet zobrazení stránek a chování webu',
                        },
                        {
                            col1: '_gcl_au',
                            col2: 'Google Tag Manager - propojení konverzí',
                        },
                        ]
                    },]
                }
            }

        }
    });

    // #c-p-bn add .accept class
    const acceptButton = document.querySelector('#c-p-bn');
    if (acceptButton) {
        acceptButton.classList.add('accept');
    }

    const acceptButton2 = document.querySelector('#s-all-bn');
    if (acceptButton2) {
        acceptButton2.classList.add('accept');
    }

    // #c-s-bn add .reject class
    const rejectButton = document.querySelector('#c-s-bn');
    if (rejectButton) {
        rejectButton.classList.add('reject');
    }

    const saveButton = document.querySelector('#s-sv-bn');
    if (saveButton) {
        saveButton.classList.add('reject');
    }




    // Add thrid button to the cookie bar {
    const buttonsContainer = document.querySelector('#c-bns');
    const buttonsContainer2 = document.querySelector('#s-bns');
    if (buttonsContainer) {
        const button = document.createElement('button');
        button.classList.add('c-bn');
        button.classList.add('c-bn--secondary');
        button.classList.add('c-bn--small');
        button.classList.add('setting');
        button.classList.add('bg-transparent');
        button.classList.add('bg-transparent');
        button.classList.add('p-1');
        button.classList.add('pt-2');
        button.classList.add('mt-2');
        button.innerHTML = 'Nastavení cookies';
        buttonsContainer.append(button);

        button.addEventListener('click', () => {
            cookieconsent.showSettings()
        });
    }

    if (buttonsContainer2) {
        const button = document.createElement('button');
        button.classList.add('c-bn');
        button.classList.add('c-bn--secondary');
        button.classList.add('c-bn--small');
        button.classList.add('setting');
        button.classList.add('bg-transparent');
        button.classList.add('bg-transparent');
        button.classList.add('p-1');
        button.classList.add('pt-2');
        button.classList.add('mt-2');
        button.innerHTML = 'Odmítnout vše';
        buttonsContainer2.append(button);

        button.addEventListener('click', () => {
            cookieconsent.accept([]);

            cookieconsent.hide();
            cookieconsent.hideSettings();
        });
    }

    if (!cookieconsent.validCookie('cc_cookie')) {
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: "CookieConsent",
            consentType: "empty"
        });
    }

    const changeSettings = document.getElementById('changeCookiesSettings');

    if (changeSettings) {
        changeSettings.addEventListener('click', () => {
            cookieconsent.showSettings()
        });
    }
});