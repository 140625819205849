const toggler = document.getElementById('myAccToggler');
const navbar = document.getElementById('myAccNavbar');
const navbarToggler = document.getElementById('myAccClose');

if (toggler && navbar) {
    toggler.addEventListener('click', function () {
        navbar.classList.add('show');
        navbarToggler.setAttribute('aria-expanded', 'true');
    });

    navbarToggler.addEventListener('click', function (e) {
        navbar.classList.remove('show');
    });
}

/* PROFILE PICTURE */
const profilePic = document.getElementById('profilePic');

if (profilePic) {
    document.getElementById('profilePic').addEventListener('change', function () {
        if (this.files[0]) {
            var picture = new FileReader();
            picture.readAsDataURL(this.files[0]);
            picture.addEventListener('load', function (event) {
                document.getElementById('uploadedImage').setAttribute('src', event.target.result);
            });
        }
    });
}


document.addEventListener('DOMContentLoaded', function () {
    const createCourseForm = document.getElementById('createCourseForm');

    if (createCourseForm) {
        createCourseForm.querySelector('#uploadImage').addEventListener('change', function () {
            if (this.files[0]) {
                var picture = new FileReader();
                picture.readAsDataURL(this.files[0]);
                picture.addEventListener('load', function (event) {
                    document.getElementById('uploadedImage').setAttribute('src', event.target.result);
                    document.getElementById('uploadedImage').classList.remove('d-none');
                });
            }
        });
    }
});