import { tns } from "tiny-slider/src/tiny-slider";

const coursesSlider = document.getElementsByClassName('courses-slider')[0];

if (coursesSlider) {

    var slider = tns({
        container: coursesSlider,
        items: 1,
        autoplay: true,
        center: true,
        navPosition: 'bottom',
        controlsPosition: 'bottom',
        gutter: 25,
        mouseDrag: true,
        navAsThumbnails: true,
        autoplayButtonOutput: false,
        controlsText: ['', ''],
        responsive: {
            576: {
                items: 2
            },
            992: {
                center: true,
                items: 3
            },
            1200: {
                center: false,
                items: 4
            }
        }
    });


    let tnsNavCourses = document.querySelector('.courses .tns-nav');
    let tnsControlsCourses = document.querySelector('.courses .tns-controls');

    let tnsNavWrapper = document.createElement('div');
    tnsNavWrapper.classList.add('tns-nav-container');

    let wrapper = document.createElement('div');
    wrapper.classList.add('tns-container');

    if (tnsNavCourses) {
        tnsNavCourses.parentNode.insertBefore(tnsNavWrapper, tnsNavCourses);
        tnsNavWrapper.appendChild(tnsNavCourses);

        tnsNavWrapper.parentNode.insertBefore(wrapper, tnsNavWrapper);
        wrapper.appendChild(tnsNavWrapper);
        wrapper.appendChild(tnsControlsCourses);


        let prependNumber = document.createElement('span');
        prependNumber.classList.add('number');
        prependNumber.classList.add('me-2');

        prependNumber.innerHTML = '01';

        let appendNumber = document.createElement('span');
        let slideCount = slider.getInfo().slideCount;

        appendNumber.classList.add('number');
        appendNumber.classList.add('ms-2');

        if (slideCount < 10) {
            slideCount = '0' + slideCount;
        }

        appendNumber.innerHTML = slideCount;

        tnsNavWrapper.prepend(prependNumber);
        tnsNavWrapper.append(appendNumber);

        let allCourses = document.createElement('a');
        allCourses.href = '/kurzy';
        allCourses.classList.add('number');
        allCourses.classList.add('d-none');
        allCourses.classList.add('d-lg-block');
        allCourses.classList.add('text-dark');
        allCourses.classList.add('text-decoration-none');
        allCourses.classList.add('ms-4');
        allCourses.innerHTML = 'Další Naše nabídka'

        tnsNavWrapper.append(allCourses);
    }
}

const reviewsSlider = document.getElementsByClassName('reviews-slider')[0];

if (reviewsSlider) {

    var slider = tns({
        container: reviewsSlider,
        items: 1,
        autoplay: true,
        center: true,
        navPosition: 'bottom',
        controlsPosition: 'bottom',
        gutter: 25,
        mouseDrag: true,
        navAsThumbnails: true,
        autoplayButtonOutput: false,
        nav: false,
        controlsText: ['', ''],
    });

}

const gallerSlider = document.querySelector('.gallery-slider');

if (gallerSlider) {

    var slider = tns({
        container: '.gallery-slider',
        autoWidth: true,
        loop: false,
        autoplay: true,
        navPosition: 'bottom',
        controlsPosition: 'bottom',
        gutter: 10,
        mouseDrag: true,
        navAsThumbnails: true,
        autoplayButtonOutput: false,
        nav: false,
        controls: false,
        responsive: {
            576: {
                items: 4
            },
            992: {
                items: 5
            },
        }
    });
}


const gallerSliderBig = document.getElementsByClassName('gallery-slider-big')[0];

if (gallerSliderBig) {

    var slider = tns({
        container: gallerSliderBig,
        loop: false,
        autoWidth: true,
        autoplay: true,
        navPosition: 'bottom',
        controlsPosition: 'bottom',
        gutter: 10,
        mouseDrag: true,
        navAsThumbnails: true,
        autoplayButtonOutput: false,
        nav: false,
        controls: true,
        controlsText: ['', ''],
    });
}
