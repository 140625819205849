export class Loader {
    constructor(container = null) {
        if (container) {
            this.loader = container;
        } else {
            this.loader = document.getElementById("loaderContainer");
        }
    }

    show() {
        this.loader.classList.add("d-flex");
    }

    hide() {
        this.loader.classList.remove("d-flex");

        setTimeout(() => {
            this.loader.classList.add("d-none");
        }, 1500);
    }
}