
(() => {
	const draggable = document.querySelectorAll(".draggable");
	
	if (!draggable) {
		return;
	}

	const preventClick = (e) => {
		e.preventDefault();
		e.stopImmediatePropagation();
	}
	let isDown = false;
	let isDragged = false;
	let startX;
	let scrollLeft;

	Array.prototype.slice.call(draggable).forEach( function(draggable) {

		draggable.addEventListener("mousedown", e => {
			isDown = true;
			draggable.classList.add("active");
			startX = e.pageX - draggable.offsetLeft;
			scrollLeft = draggable.scrollLeft;
		});
		draggable.addEventListener("mouseleave", () => {
			isDown = false;
			draggable.classList.remove("active");
		});
		draggable.addEventListener("mouseup", (e) => {
			isDown = false;
			const elements = document.querySelectorAll("a");
			if(isDragged){
				for(let i = 0; i<elements.length; i++){
					elements[i].addEventListener("click", preventClick);
				}
			}
			else{
				for(let i = 0; i<elements.length; i++){
					elements[i].removeEventListener("click", preventClick);
				}
			}
			draggable.classList.remove("active");
			isDragged =  false;
		});
		draggable.addEventListener("mousemove", e => {
			if (!isDown) return;
			isDragged =  true;
			e.preventDefault();
			const x = e.pageX - draggable.offsetLeft;
			const walk = (x - startX) * 2;
			draggable.scrollLeft = scrollLeft - walk;
		});

	});
})();
