import Quill from 'quill';
var Block = Quill.import('blots/block');
Block.tagName = 'span';
Quill.register(Block, true);

function getVariableData(variableId) {
    const getDataUrl = `/admin/page-variables/${variableId}/edit`;

    fetch(getDataUrl, {
        method: 'GET',
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        },
        mode: 'cors',
        cache: 'default',
    })
        .then(result => result.text())
        .then(data => {
            document.getElementById('updateVariableModalBody').innerHTML = data;

            const quill = document.getElementsByClassName('update-variable-quill');

            const toolbarOptionsProfile = [
                ['bold', 'italic', 'underline', 'strike'],
                ['link'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }]
            ];

            if (quill.length) {
                const content = document.querySelector('.update-variable-editorjs');

                let editor = new Quill('.update-variable-editorjs', {
                    modules: {
                        toolbar: toolbarOptionsProfile,
                    },
                    theme: 'snow'
                });

                if (content) {
                    document.getElementById('updateVariableForm1').addEventListener('submit', function (e) {
                        e.preventDefault;
                        let content = document.querySelector('input[name=value]');
                        content.value = (editor.root.innerHTML).replace('<span><br></span>', '');
                    })
                }
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}



/*
async function updateVariable(variableId, data) {
    const postUrl = `/admin/page-variables/${variableId}`;
    const variableNameInput = updateVariableModal.querySelector('#variableName');
    const variableValueInput = updateVariableModal.querySelector('#variableValue').value;
    const submitButton = updateVariableModal.querySelector('button[type="submit"]');

    submitButton.classList.add('icon-loading');
    const value = variableValueInput.replace(/(?:\r\n|\r|\n)/g, '<br>');

    console.log(JSON.stringify(Object.fromEntries(data));



    await fetch(postUrl, {
        method: 'PUT',
        body: data,
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
    })
        .then(response => response.json())
        .then(data => {
            let alert = document.createElement('div');
            alert.className = 'alert bs-alert';
            alert.innerHTML = data.message;
            document.body.appendChild(alert);
            // location.reload();
            setTimeout(function () {
                alert.remove();
            }, 4500);

            console

            submitButton.classList.remove('icon-loading');
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}
*/

document.addEventListener("DOMContentLoaded", function (event) {
    const updateVariableModal = document.getElementById('updateVariableModal');

    if (updateVariableModal) {
        updateVariableModal.addEventListener('show.bs.modal', function (event) {
            const form = updateVariableModal.querySelector('#updateVariableForm');
            const variableId = event.relatedTarget.getAttribute('data-id');
            getVariableData(variableId);
        });
    }
});

