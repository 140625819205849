function slugify(text) {
    return text
        .toString()
        .normalize('NFKD')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-');
}

window.addEventListener('DOMContentLoaded', () => {
    const pageTitle = document.getElementById('pageTitle');
    const pageSlug = document.getElementById('pageSlug');

    if (pageSlug && pageTitle) {
        pageTitle.addEventListener('keyup', () => {
            pageSlug.value = slugify(pageTitle.value);
        });
    }
});