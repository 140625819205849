
import Quill from 'quill';

window.addEventListener('DOMContentLoaded', () => {
    const toolbarOptionsProfile = [
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }]
    ];

    const editors = document.querySelectorAll('.editorjs');

    editors.forEach(editor => {
        const editorId = editor.getAttribute('id');
        const editorContent = document.getElementById(editorId).innerHTML;

        const quill = new Quill('#' + editorId, {
            modules: {
                toolbar: toolbarOptionsProfile
            },
            theme: 'snow'
        });

        quill.on('load', function () {
            quill.root.innerHTML = editorContent;

            const input = document.getElementById(editorId + '-input');
            input.value = editorContent;

            console.log(editorContent);
        });

        quill.on('text-change', function (delta, oldDelta, source) {
            const content = quill.root.innerHTML;
            const input = document.getElementById(editorId + '-input');
            input.value = content;
            console.log(content);
        });
    });


    // on form submit set the content of the editor to the input field
    const forms = document.querySelectorAll('form');

    forms.forEach(form => {
        form.addEventListener('submit', () => {
            editors.forEach(editor => {
                /*const editorId = editor.getAttribute('id');
                const content = editor.root.innerHTML;
                const input = document.getElementById(editorId + '-input');
                input.value = content;*/
            });
        });
    });
});


