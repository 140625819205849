import { Loader } from "./components/loader";
const loader = new Loader();

document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('form').forEach(form => {
        form.addEventListener('submit', (e) => {
            if (form.classList.contains('is-submitting')) {
                e.preventDefault();
            }

            form.classList.add('is-submitting');
            form.querySelectorAll('button').forEach(button => {
                button.disabled = true;
            });

            // Show laoder
            loader.show();
        });
    });

    // delegate event listener to the document
    document.addEventListener('submit', function (e) {

        // if the submit event was not triggered by a form, ignore
        if (!e.target.matches('form')) return;

        // if the form was already submitted, ignore
    if (e.target.classList.contains('is-submitting')) return;

        // prevent multiple submissions
        e.target.classList.add('is-submitting');
        e.target.querySelectorAll('button').forEach(button => {
            button.disabled = true;
        });

        // Show laoder
        loader.show();

        // hide loader after 5 seconds
        setTimeout(function () {
            loader.hide();
        }, 5000);
    });
});