const uploadImage = document.getElementById('uploadImage');

if (uploadImage) {
    uploadImage.addEventListener('change', function () {
        if (this.files[0]) {
            var picture = new FileReader();
            picture.readAsDataURL(this.files[0]);
            picture.addEventListener('load', function (event) {
                uploadImage.setAttribute('src', event.target.result);
                uploadImage.classList.remove('d-none');
            });
        }
    });
}
