window.addEventListener("load", function () {
    const addCourseAddress = document.getElementById('addCourseAddress');
    const isOnlineCourse = this.document.querySelectorAll('[name="is_online"]');

    if (addCourseAddress) {
        isOnlineCourse.forEach(element => {
            element.addEventListener('change', function () {
                const val = this.value;

                if (val === '1') {
                    addCourseAddress.classList.add('d-none');
                } else {
                    addCourseAddress.classList.remove('d-none');
                }
            });
        });
    }
});

/*
window.addEventListener("load", function () {
    const isExternalCourse = this.document.querySelectorAll('[name="is_external_course"]');
    const notExternalCourseInformation = this.document.getElementById('notExternalCourseInformation');

    if (notExternalCourseInformation) {
        isExternalCourse.forEach(element => {
            element.addEventListener('change', function () {
                const val = this.value;
                
                if(this.checked === true) {
                    notExternalCourseInformation.classList.add('d-none');
                } else {
                    notExternalCourseInformation.classList.remove('d-none');
                }
            });
        });
    }
});
*/

window.addEventListener("load", function () {
    const addNewCourseItem = document.getElementById('addNewCourseItem');
    const courseItems = this.document.getElementById('courseItems');

    if (addNewCourseItem && courseItems) {
        let x = 1;
        addNewCourseItem.addEventListener('click', function () {
            const courseItem = courseItems.querySelectorAll('.course-item')[0].cloneNode(true);
            courseItem.querySelectorAll('textarea').forEach(element => {
                x++;
                element.value = '';
                element.setAttribute('name', element.getAttribute('name').replace(/\d+/, x));
            });
            courseItems.appendChild(courseItem);
        });


        this.document.addEventListener('click', function (e) {
            if (e.target.classList.contains('remove-course-item')) {
                e.target.parentNode.remove();
            }
        }
        );
    }
});

window.addEventListener("load", function () {
    const certificationDescription = document.getElementById('certificationDescription');
    const hasCertificate = this.document.querySelectorAll('[name="has_certificate"]');

    if (hasCertificate && certificationDescription) {
        hasCertificate.forEach(element => {
            element.addEventListener('change', function () {

                if (this.checked === true) {
                    certificationDescription.classList.remove('d-none');
                } else {
                    certificationDescription.classList.add('d-none');
                }
            });
        });
    }
});
