import { Modal } from 'bootstrap';

const loader = document.getElementById("loaderContainer");
function displayLoading() {
    loader.classList.add("d-flex");
}

function hideLoading() {
    loader.classList.remove("d-flex");
}


if (document.getElementById('cartModal')) {
    var cartModal = new Modal(document.getElementById('cartModal'), {
        keyboard: false
    });

    cartModal.show();
}

function initCart() {
    const numberDecrements = document.querySelectorAll('.input-number-decrement');
    const numberIncrements = document.querySelectorAll('.input-number-increment');
    const numberCount = document.querySelectorAll('.input-number');

    numberCount.forEach((input) => {

        input.addEventListener('change', () => {
            const count = input.value;

            if (count < 1) {
                input.value = 1;
                count = 1;
            }

            const rowId = input.getAttribute('data-row-id');
            const url = input.getAttribute('data-url');
            changeProductCount(rowId, count, url);
        })
    });


    numberDecrements.forEach((button) => {
        button.addEventListener('click', function () {

            const min = 1;
            let valueCount = button.parentNode.querySelector('.input-number').value;

            if (valueCount <= min) {
                return false;
            }

            valueCount--;

            button.parentNode.querySelector('.input-number').value = valueCount;
            button.parentNode.querySelector('.input-number').dispatchEvent(new Event('change'));
        })
    })


    numberIncrements.forEach((button) => {
        button.addEventListener('click', function () {
            let valueCount = button.parentNode.querySelector('.input-number').value;
            const max = button.parentNode.querySelector('.input-number').getAttribute('max');

            if (max && valueCount >= max) {
                return false;
            }

            valueCount++;
            button.parentNode.querySelector('.input-number').value = valueCount;
            button.parentNode.querySelector('.input-number').dispatchEvent(new Event('change'));
        })
    })

}

initCart();

function changeProductCount(rowId, qty, url) {;
    displayLoading();

    fetch(url, {
        method: 'PUT',
        body: JSON.stringify({
            qty: qty
        }),
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
    })
        .then((response) => {
            if (response.ok) {
                return response;
            }
            return Promise.reject(response);
        })
        .then(result => result.text())
        .then(data => {
            hideLoading();
            document.getElementById('cartContentAjax').innerHTML = data;
            initCart();
        })
        .catch(function (err) {
            hideLoading();
            console.log(err)
        });

}


const roleRadioButton = document.querySelectorAll('.role-radio-button');

if (roleRadioButton.length) {
    roleRadioButton.forEach((el) => {
        el.addEventListener('change', function () {
            const role = this.value;
            const studentCompany = document.getElementById('studentCompany');

            if (role === 'student_company') {
                studentCompany.classList.remove('d-none');
                studentCompany.querySelectorAll('input').forEach((input) => {
                    input.setAttribute('required', true);
                });
            } else {
                studentCompany.classList.add('d-none');
                studentCompany.querySelectorAll('input').forEach((input) => {
                    input.removeAttribute('required');
                });
            }
        })
    })
}